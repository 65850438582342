* {
  scrollbar-width: thin;
  scrollbar-color: grey transparent;
}

*::-webkit-scrollbar {
  width: 6;
}

*::-webkit-scrollbar-thumb {
  background-color: grey;
  border-radius: 10rem;
}

*::-webkit-scrollbar-track {
  background-color: transparent;
}

body {
  overflow-y: hidden;
}

/* focus-visible styles (keyboard nav) */
*:focus-visible {
  outline: solid 2px;
}

*:focus-visible::before {
  opacity: 1;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 2px;
  box-shadow: 0 0 0 2px currentColor;
  background-color: transparent;
  top: 0;
  outline: none;
  z-index: 0;

  content: '';
  transition: opacity 0.1s ease-in;
  position: absolute;
}

/* Do not apply focus highlights top nav dropdown menus */
[id^="top-navigation-dropdown-tab-menu"] .MuiMenu-paper:focus-visible,
[id^="top-navigation-dropdown-tab-menu"] .MuiMenu-paper:focus-visible::before,
[id^="top-navigation-dropdown-tab-menu"] .MuiList-root:focus-visible,
[id^="top-navigation-dropdown-tab-menu"] .MuiList-root:focus-visible::before,
#top-navigation-authentication-status-menu .MuiMenu-paper:focus-visible,
#top-navigation-authentication-status-menu .MuiMenu-paper:focus-visible::before,
#top-navigation-authentication-status-menu .MuiList-root:focus-visible,
#top-navigation-authentication-status-menu .MuiList-root:focus-visible::before {
  outline: none;
  box-shadow: none;
}

.MuiTreeItem-label a:focus-visible,
*.MuiButtonBase-root:focus-visible,
.MuiDialog-scrollPaper:focus-visible,
.MuiDialog-container:focus-visible {
  outline: none;
}
.MuiCardActionArea-root:focus-visible::before {
  margin: 5px;
  border-radius: 8px;
}

.small-spinner,
.small-spinner .MuiCircularProgress-root {
  width: 32px !important;
  height: 32px !important;
}
.execute-wrapper:empty,
.swagger-ui .execute-wrapper:empty {
  padding: 0 0 12px;
}
#main-container .swagger-ui .model-box-control:focus,
#main-container .swagger-ui .models-control:focus,
#main-container .swagger-ui .opblock-summary-control:focus {
  outline: auto;
  outline: solid 2px rgba(0, 0, 0, 0.25);
}

/* Override header sizes */
h3, [class*="Typography-h3"] {
  font-size: 20px;
}

h4, [class*="Typography-h4"] {
  font-size: 18px;
}

h5, [class*="Typography-h5"] {
  font-size: 18px;
}
